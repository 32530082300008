import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { sku } from 'src/app/services/models/product.model';
import { FormControl } from '@angular/forms';
import * as cloneDeep from 'lodash/cloneDeep';
import { CommonServices } from 'src/app/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/pages/theme/utils/messageService';
import { Router } from '@angular/router';
import { ToggleWishlistRequestModel } from 'src/app/services/models/togglewishlist-request-model';
import { CartItems, item } from 'src/app/services/models/cartitems.model';
import { EditQuantityRequestModel } from 'src/app/pages/shared/interfaces/editquantity-request-model';
import { GlobalState, GlobalStateService } from 'src/app/pages/shared/service/global-state.service';
@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {

  selectedProduct: any = null;
  attributes: Array<any> = []
  selectedAttr: any = {};
  qty: number = 1;
  wareHouseStoreId: any = null
  shopid: any;
  addtoCart: boolean =false;

  addtoCartLoading:boolean;
  addtoWishlistLoading:boolean;
  openShop:boolean= false;
  productImages = [ ]
  items: item[];
  cartitems:item[];
  inCart:boolean = false;
  loading: boolean;
  globalState: GlobalState;
  selectedImage: string;
  cartItems: any;
  enableInventory:boolean;
  userLogged:boolean;
  cartitemsSku:Array<string> = [];
  attributeDisable: boolean;

  constructor(public appService: AppService,
    private CommonService: CommonServices,
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    public snack:MatSnackBar,
    private router:Router,
    private messageService: MessageService,
    private GlobalState: GlobalStateService,
    @Inject(MAT_DIALOG_DATA) public product: any) {
      // let User = JSON.parse(sessionStorage.getItem('userData'))
      // this.shopid = User?.warehouses[0].wareHouseStoreId
      // this.retailShopId = User?.shops[0].shopId
  }

  ngOnInit() {
    this.globalState = this.GlobalState.globalData
    this.openShop = this.GlobalState.globalData?.openShop
    this.wareHouseStoreId = this.globalState?.status.warehouses[0].wareHouseStoreId
    this.shopid = this.globalState?.shops[0].shopId
    this.enableInventory = this.GlobalState.globalData.status.warehouses[0].enableInventory
    this.userLogged = this.GlobalState.globalData.userLogged
    if(this.GlobalState.globalData.cartdetails.order.length>0){
      this.cartitemsSku = this.GlobalState.globalData.cartdetails.order[0].items.item.map(e=>{
        return e.skuId
      })
    }
    this.setInitialVariantSelected()
    this.loadWishlist()
  }

  /**
   * Setting initial Variant Selected
   * and created new propert called images which includes skuimage and
   * additional imaages
   */
  setInitialVariantSelected() {
    this.attributeDisable = false;
    this.CommonService.getCartDetails().subscribe({
      next: (response: CartItems) => {
        if(response.order.length){
          this.cartitems = response.order[0]?.items.item;
          let index:any;
          index=this.cartitems.findIndex((item)=>{
              if(item.skuId==this.product.sku[0].skuId){
                return true;
              }
              else{
                return false;
              }
          })
          console.log(response)
          if(index !==-1){
            console.log('qty',this.cartitems[index].quantity)
            this.qty=this.cartitems[index].quantity
            this.inCart=true;
          }
        }
      }
    })
    let variant: sku = this.product?.sku[0]
    if(!this.inCart){
      console.log('qty not in cart',this.product.minimumquantity)
      this.qty = parseInt(this.product.minimumquantity??'0')
    }

    // this.selectedProduct = Object.assign({ images: [{ image: variant.skuImage??this.product.productImage, title: '', thumbImage: variant.skuImage }].concat(variant.additionalImages?variant.additionalImages.map(e => { return { image: e.imageUrl, title: '', thumbImage: e.imageUrl } }):[]) }, variant)
    let images = []
    let mainimg = variant.skuImage ?? this.product.productImage
    console.log(this.product)
    let additionalImages = [] =  variant.additionalImages.length ? variant.additionalImages.map(e => {
      return {
        image: e.imageUrl,
        thumbImage: e.imageUrl,
        title: ''
      }
    }) : []

    images = [...[{
      image: mainimg,
      thumbImage: mainimg,
      title: ''
    }], ...additionalImages]
    this.selectedProduct = Object.assign(
      {
        images: images
      }, variant)
    let attr = []
    this.selectedImage = this.selectedProduct.images[0].image
    this.product?.sku.forEach(e => {
      if (e.attributes?.attribute) {
        attr = attr.concat(e.attributes.attribute)
      }
    })

    let removeDuplicates = cloneDeep(attr.filter((value, index, self) => {
      return index === self.findIndex((i) => (i.value == value.value))
    }))
    let uniqueAttr = removeDuplicates.filter((value, index, self) => {
      return index === self.findIndex((i) => (i.name == value.name))
    })
    let formatedAttr = []
    uniqueAttr.forEach(u => {
      let format = { skuDefining: u.skuDefining, name: u.name, value: [] }
      removeDuplicates.forEach(r => {
        if (u.name == r.name) {
          format.value.push(r.value)
        }
      })
      formatedAttr.push(format)
    })
    this.attributes = formatedAttr

    let _selectedAttr = {}
    uniqueAttr.forEach((e: any) => {
      Object.assign(_selectedAttr, { [e.name]: e })
    })
    this.selectedAttr = _selectedAttr;
  }


  public close(): void {
    this.dialogRef.close();
  }

  /**
   * Selecting product if variants changed
   * @param name
   * @param value
   */
  changeProduct(name, value) {
    let selectedPrd = this.product.sku.filter((e: sku) => {
      return e.attributes?.attribute.every(attr => {
        return attr.name == this.selectedAttr[attr.name]?.name && attr.value == this.selectedAttr[attr.name].value
      })
    })[0]
    if (selectedPrd) {
      this.selectedProduct = Object.assign(
        {
          images: [{
            image: selectedPrd.skuImage ?? this.product.productImage, title: '',
            thumbImage: selectedPrd.skuImage ?? this.product.productImage
          }].concat(selectedPrd.additionalImages.map(e => { return { image: e.imageUrl, title: '', thumbImage: e.imageUrl } }))
        }, selectedPrd)
        this.selectedImage = this.selectedProduct.images[0].image
        let index:any;
        index=this.cartitems.findIndex((item)=>{
          if(item.skuId==this.selectedProduct.skuId){
            return true;
          }
          else{
            return false;
          }
        })
    
        if(index !==-1){
          console.log('qty',this.cartitems[index].quantity)
          this.qty=this.cartitems[index].quantity
          this.inCart=true;
        }
      }
      else{
        this.snack.open('This variant is not available', '', { panelClass: 'error', duration: 3000 })
        this.attributeDisable = true
  
      }
  }

  /**
   *Adding selected product to Cart
   */
  addToCart() {
    this.addtoCart =true;
    console.log('llll',this.selectedProduct)
    let payload = {
      "retailerShopId": this.shopid,
      "qty": this.qty.toString(),
      "skuId": this.selectedProduct.skuId,
      "uom": this.selectedProduct.uom.value
    }
    this.addtoCartLoading = true;
    this.CommonService.addToCart(payload).subscribe({
      next:(res:any)=>{
        if(res?.order[0].header.status.value=="FAILED"){
          this.snack.open(res?.order[0].header.status.message,'',{duration:3000})
        }
        else{
          // sessionStorage.setItem('cartItems', JSON.stringify(res?.order[0]?.items.item));
          this.cartitemsSku = res.order[0]?.items.item.map(e=>{
            return e.skuId
          })
          if(res.order.length >0){
            this.GlobalState.updateGlobalData({'cartdetails': res})
            this.messageService.sendMessage(JSON.stringify({'cartdetails':res}))
            this.messageService.sendMessage(JSON.stringify({cartitemsCount:res?.order[0]?.items.item.length}))
          }

          if(res?.order[0].header.status.message?.trim()==''){
           // this.snack.open('Item added to cart!','',{duration:3000})
          }
          else{
            this.snack.open(res?.order[0].header.status.message,'',{duration:3000})
          }
        }
      },
      error:(err)=>{
        this.snack.open('Some thing went wrong!')
        this.addtoCartLoading = false;
      },
      complete:()=>{
        this.addtoCartLoading = false;
      }
    })
  }
/**
 * Update Product Cart Quantity
 * @param qty
 * @param item
 */
  updateCart(qty, item) {
    if(qty<parseInt(this.product?.minimumquantity)){
      this.qty = parseInt(this.product?.minimumquantity)
      this.snack.open(`Minimum ${this.product?.minimumquantity} quantity required!`,'Close', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 })
    }
    else {
      let editquantityrequest: EditQuantityRequestModel = {
        items: []
      }
      editquantityrequest.items.push({
        skuId: item.skuId,
        qty: qty

      })
      this.CommonService.updateQuantity(editquantityrequest).subscribe({
        next: (response: any) => {
          this.items = response.order[0]?.items.item

          this.messageService.sendMessage(JSON.stringify({ 'cartdetails': response }))
          /**
           * call a method will update the product item isCart[] with this reponse and update the item in globalstate too
           */
          this.cartitems = response.order[0]?.items.item
          this.cartitemsSku = response.order[0]?.items.item.map(e => {
            return e.skuId
          })
          this.GlobalState.updateGlobalData({ 'cartdetails': response })
          this.messageService.sendMessage(JSON.stringify({ cartitemsCount: response.order[0].items.item.length }))
        }
      })
    }
  }
  /**
   * Remove Product From Cart
   * @param id
   */
  removeProduct(){
    let id = this.selectedProduct.skuId
    this.CommonService.removeProductFromCart(id).subscribe({
      next: (response) => {
        if (response.order[0].header.status.value === 'CART' || response.order[0].header.status.value === 'OCART') {
          this.items = this.items?.filter(e => {
            return e.skuId != id
          })
          this.messageService.sendMessage(JSON.stringify({'cartdetails':response}))
          /**
           * call a method will update the product item isCart[] with this reponse and update the item in globalstate too
           */
          this.cartitems=response.order[0]?.items.item
          this.cartitemsSku = response.order[0]?.items.item.map(e=>{
            return e.skuId
          })
          if(response.order.length>0){
            this.GlobalState.updateGlobalData({'cartdetails': response})
            this.messageService.sendMessage(JSON.stringify({ cartitemsCount: response.order[0].items.item.length }))
          }

          // this.getCartItems();
          this.snack.open('Successfully deleted', '', { duration: 3000 })
        }
        else {
          this.snack.open(response.order[0].header.status.message, '', { duration: 3000 })
        }
      },
      error: (err) => {
        this.snack.open('Some thing went wrong!', '', { duration: 3000 })
        console.log(err)
      }
    })
  }
  addQuantity(product){

    this.qty = Number(this.qty ?? product.minimumquantity) + 1;
    this.addToCart()
  }
  lessQuantity(product){
    if(this.qty == product.minimumquantity){
      this.snack.open( "can't buy below quantity ",'',{duration:3000})

    }
    else{
      this.qty= Number(this.qty) - 1;
    this.addToCart()
    }
  }
  /**
   * Adding Selected product to Wishlist
   */
  addToWishlist(product) {
      let togglewishlistrequest: ToggleWishlistRequestModel = {
        qty: "0",
        skuId: this.selectedProduct.skuId,
        uom: this.selectedProduct.uom.value,
        action: "ADD"
      };
      this.CommonService.toggleWishlist(togglewishlistrequest).subscribe({
        next: (response: any) => {
          if (response.status.success === true) {
            product.isFavorite = ! product.isFavorite
            const index = this.items.findIndex(item =>
              {
                  if(item.skuId === this.product.sku[0].skuId){
                    return true;
                  }
                  else{
                    return false;
                  }
             });
            if (index === -1) {
              this.items.push(this.product.sku[0]);
              this.product.sku[0].isFavorite=true;
            }
            else if (index !== -1) {
              this.items.splice(index, 1);
              this.product.sku[0].isFavorite=false;
            }
            this.messageService.sendMessage(JSON.stringify({wishlistcount:this.items.length}))
            this.snack.open(response.status.message, '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 })
          }
        }
      })
  }

  loadWishlist() {
    if (sessionStorage.getItem('wishlistdetails')) {
      this.items = JSON.parse(sessionStorage.getItem('wishlistdetails')).response.order[0].items.item;
    }
    else {
      this.loading = true;
      this.CommonService.getWishlistItems().subscribe({
        next: (response: CartItems) => {
          if(response.order[0]?.items)
          this.items = response.order[0].items.item;
          else
          this.items=[]
        },
        error: (err) => {
          this.snack.open('Some thing went wrong!', '', { duration: 3000 })
          this.loading = false;
          console.log(err)
        },
        complete: () => {
          this.loading = false
        }
      })
    }
  }


  changeImage(image){
    this.selectedImage = image;
  }

  navigateProductDetail(product){
    sessionStorage.setItem('skuproduct',JSON.stringify(this.product))
    this.router.navigate(['/home/products/details'],{queryParams:{sku:product.skuId,id:this.product.id}})
    this.close()
  }

    /**
   * easy access for html to validate selected product is out of stock or not
   * @param product
   * @param selectedProduct
   */
    checkOutofStock(product,selectedProduct){
      if(parseInt(selectedProduct.stock)<parseInt(product.minimumquantity) || parseInt(selectedProduct.stock)<0){
        return true
      }
      else{
        return false
      }
    }

    /**
   * Get method for easy access of cartitensku array in html
   */
  get allreadyAddeditems(){
    // console.log('id', this.cartitemsSku)
    return this.cartitemsSku
  }

}
