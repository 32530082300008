// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --bolt` replaces `environment.ts` with `environment.bolt.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    url: '',
    serviceUrl: 'https://services.boltb2b.co.za/storimartservices/',
    appName: 'storimartRSSA',
    source :"EUAPP",
    logo:"../../../../../assets/images/logos/storimart.png",
    website:'https://storimart.com/',
    twitterUrl : 'https://twitter.com/',
    linkedinUrl : 'https://www.linkedin.com/company/storimart-b2b',
    facebookUrl : 'https://www.facebook.com/storimart',
    blckUrl:'http://localhost:4200/'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  