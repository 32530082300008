<div>
    <div mat-dialog-title class="quickviewClosebtn">
        <button mat-icon-button class="close-button" (click)="close()">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    
    <div mat-dialog-content class="quickviewContent">
        <div class="quickviewimage">
            <div>
                <div style="place-items: center;">
                    <div fxLayoutAlign="end" class="-btn">
                        <button *ngIf="userLogged" mat-icon-button matTooltip="Add to wishlist"  [disabled]="attributeDisable"
                            class="storimartCustomFavIcon" (click)="addToWishlist(selectedProduct)"
                            style="color:rgb(240 36 85);position: absolute;"><mat-icon
                                *ngIf="selectedProduct.isFavorite">favorite</mat-icon><mat-icon
                                *ngIf="!selectedProduct.isFavorite">favorite_outline</mat-icon></button>
                    </div>
                    <img [src]="selectedImage" onerror="this.src='assets/images/carousel/placeholder-product.png'"
                        alt="" srcset="" height="250px" style="    border-radius: 10px;
                                box-shadow: rgb(213 213 213) 0px 1px 5px -1px;
                                display: block;
                                width: 300px;
                                height: 300px;
                                object-fit: cover;">
                    <ngx-avatar *ngIf="selectedProduct.images[0].image[0].includes('no_Img')" initialsSize="3"
                        textSizeRatio="3" [size]="250" name="{{selectedProduct.skuShortName}}"
                        [round]="false"></ngx-avatar>

                </div>
                <div class="secondaryimagesdiv">
                    <div *ngFor="let images of selectedProduct?.images.slice(0, 3);">
                        <img [src]="images?.image" class="cursor secondaryimages" (click)="changeImage(images?.image)"
                            [ngClass]="{'activeimage': images?.image==selectedImage}" alt="" srcset="">
                    </div>
                </div>
            </div>
        </div>
        <div class="qvdetails">
            <mat-card-title class="quickvprdtitle">{{selectedProduct.skuShortName??selectedProduct.skuLongName}}</mat-card-title>
            <mat-card-subtitle>{{product?.categoryName}}</mat-card-subtitle>
            <h5><strong>Product ID : </strong>{{selectedProduct?.skuShopId??product?.shopProductId??selectedProduct?.skuErpId??product?.productErpId}}</h5>
            <div *ngIf="userLogged ">
                <p>
                    Price:&nbsp;<span *ngIf="selectedProduct.skuPrice.offer"
                        [ngClass]="{'textRed': selectedProduct.skuPrice.offer}">{{selectedProduct.skuPrice.offer|currency:selectedProduct.skuPrice.currency}}</span>&nbsp;
                    <span *ngIf="selectedProduct.skuPrice.actual" class="textRed"
                        [ngClass]="{'strikeoutprice': selectedProduct.skuPrice.offer}">{{selectedProduct.skuPrice.actual|currency:selectedProduct.skuPrice.currency}}</span>
                </p>
            </div>
            <br />

            <div class="customInputFlex" *ngFor="let attr of attributes">
                <mat-form-field appearance="fill" class="storimartCustomSelect">
                    <mat-label>{{attr.name}}</mat-label>
                    <mat-select [(ngModel)]="selectedAttr[attr.name].value" (ngModelChange)="changeProduct(attr.name)">
                        <mat-option *ngFor="let item of attr.value" [value]="item">
                            {{item}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div>
                <div *ngIf="enableInventory">
                    
                </div>
                <div *ngIf="!enableInventory">
                    
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="qvbottomactions" *ngIf="userLogged">
        <button mat-button color="primary" (click)="navigateProductDetail(selectedProduct)">View full product details</button>
        <div *ngIf="enableInventory"  class="d-flex">
            <div class="qty_input_product_details_qv"
                        *ngIf="!checkOutofStock(product,selectedProduct) && allreadyAddeditems.includes(selectedProduct.skuId) && !attributeDisable">
                        <button mat-raise-button (click)="qty=qty-1;updateCart(qty,selectedProduct)">
                            <mat-icon>remove</mat-icon>
                        </button>
                        <input class="productcardinput" type="number" [(ngModel)]="qty"
                            (change)="updateCart($event.target.value,selectedProduct)" [value]="qty">
                        <button mat-raise-button (click)="qty=qty+1;updateCart(qty,selectedProduct)">
                            <mat-icon>add</mat-icon> 
                        </button>
                    </div>
            <div class="buttonGroupactionqv">
                <button mat-raised-button color="primary"
                    *ngIf="!checkOutofStock(product,selectedProduct) && !allreadyAddeditems.includes(selectedProduct.skuId) && !attributeDisable"
                    (click)="addToCart()">Add To Cart</button>
                <button mat-raised-button disabled *ngIf="checkOutofStock(product,selectedProduct) && !attributeDisable">Out Of Stock</button>
                <button mat-raised-button color="primary" (click)="removeProduct()" *ngIf="!checkOutofStock(product,selectedProduct) && allreadyAddeditems.includes(selectedProduct.skuId) && !attributeDisable">
                        <span>Remove From Cart</span>
                </button>
                <button mat-raised-button  *ngIf="attributeDisable">
                    <span>This variant is not available</span>
                </button>
            </div>
        </div>
        <div *ngIf="!enableInventory" class="d-flex">
            <div class="qty_input_product_details_qv" *ngIf="allreadyAddeditems.includes(selectedProduct.skuId) && !attributeDisable">
                <button mat-raise-button (click)="qty=qty-1;updateCart(qty,selectedProduct)">
                    <mat-icon>remove</mat-icon> 
                </button>
                <input class="productcardinput" type="number" [(ngModel)]="qty"
                    (change)="updateCart($event.target.value,selectedProduct)" [value]="qty">
                <button mat-raise-button (click)="qty=qty+1;updateCart(qty,selectedProduct)">
                    <mat-icon>add</mat-icon> 
                </button>
            </div>
            <div class="buttonGroupactionqv">
                <button mat-raised-button color="primary" *ngIf="!allreadyAddeditems.includes(selectedProduct.skuId) && !attributeDisable" (click)="addToCart()">Add To Cart</button>
                <button mat-raised-button color="primary" (click)="removeProduct()" *ngIf="allreadyAddeditems.includes(selectedProduct.skuId) && !attributeDisable"><span>Remove From Cart</span></button>
                <button mat-raised-button  *ngIf="attributeDisable">
                    <span>This variant is not available</span>
                </button>
            </div>
        </div>
    </div>
</div>